<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-teklif-aciklama"
      title="Teklif Açıklama"
      ok-title="Kaydet"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      no-enforce-focus
      centered
      size="lg"
      v-if="modalObject.item"
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <validation-observer ref="teklifAciklama">
        <b-form ref="form" @submit.stop.prevent="saveTeklifAciklama">
          <b-form-group label="Açıklama Türü">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Açıklama Türü"
            >
              <v-select
                v-model="modalObject.item.teklifAciklamaTuru"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="teklifAciklamaTuruOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Sıra">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Sıra"
            >
              <b-form-input
                v-model="modalObject.item.ldSira"
                min="1"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="" label-for="contact-options" label-class="mb-1">
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="modalObject.item.isActive"
                class="custom-control-primary"
              >
                {{ $t("Aktif Durumu") }}
              </b-form-checkbox>
            </div>
          </b-form-group>

          <b-form-group :label="$t('Açıklama')">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Açıklama"
            >
              <editor
                v-model="modalObject.item.sAciklama"
                api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
                :init="{
                  plugins: [
                    'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage',
                  ],
                  toolbar:
                    'undo redo | bold italic underline strikethrough | \
                  fontselect fontsizeselect formatselect | \
                  alignleft aligncenter alignright alignjustify | outdent indent | \
                  numlist bullist checklist | \
                  forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
                  fullscreen  preview  print | insertfile image media pageembed link anchor | \
                  a11ycheck ltr rtl | showcomments addcomment | uploadimage',
                  language: 'tr_TR',
                  min_height: 200,
                  valid_elements: '*[*]',
                  extended_valid_elements: 'style,link[href|rel]',
                  custom_elements: 'style,link,~link',
                  force_br_newlines: true,
                  forced_root_block: false,
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Editor from '@tinymce/tinymce-vue';
import AlertService from '@/common/alert.service'
import StaticOptions from '@/common/options/StaticOptions'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
    Editor,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      teklifAciklamaTuruOptions: StaticOptions.teklifAciklamaTuruOptions,
      aciklamaData: {
        sAciklama: null,
        teklifAciklamaTuru: null,
        isActive: true,
      },
    }
  },
  methods: {
    saveTeklifAciklama() {
      this.$refs.teklifAciklama.validate().then(success => {
        if (success) {
          this.$store.dispatch('teklifAciklamaYonetim/saveTeklifAciklama', this.modalObject.item, this)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                this.modalObject.onSuccess();
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    initialModal() {
      this.$refs['modal-save-teklif-aciklama'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveTeklifAciklama()
    },
    hiddenModal() {
      this.$refs['modal-save-teklif-aciklama'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
